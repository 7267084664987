import styled from "styled-components"

export const HeroBanner = styled.section`
  position: relative;	
  background-color:${props => props.bgColors};   
  height: 280px;
  @media(min-width:768px){
    height: 300px;
  }
  @media(min-width:992px){
    height: 400px;
  }
  @media(min-width:1200px){
    height: 500px;
  }
  @media(min-width:1600px){
    height: 600px;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

HeroBanner.defaultProps = {
  bgColors: "#373636",
}

export const HeroImage = styled.div`
  position: relative;	
  background-color:${props => props.bgColors};   
  background-image: ${props=>props.bgImg};
  opacity:${props=>props.opacity};
  background-position: top center;
  background-repeat: no-repeat;
  height: 100%;
  background-size:cover;
  @media(min-width:1200px){
    background-size:inherit;
    background-attachment: fixed;
  }
`

HeroImage.defaultProps = { 
  opacity: "0.7",
}