import React from "react"
import styled from "styled-components"
import { MapIcon } from "../Icons"

const ListTitle = styled.div`
  text-transform:uppercase;
  font-weight:600;
  margin-bottom:15px;
  font-size:20px;
  line-height:30px;
  @media(min-width:1440px){
    font-size:24px;
    line-height:34px;  
  }
`

const ListInfo = styled.div`
  margin-bottom:40px;
`
const ListItemInfo = styled.div`
  display:flex;
  flex-wrap:nowrap;
  align-items: self-start;
  color:#fff;    
  font-size:16px;
  line-height:28px;
  @media(min-width:1600px){
    font-size:18px;
    line-height:30px;
  }
  a{
    display:inline-block;
    color:#fff;   
    
    position:relative;
    &:after, &:before{
      -webkit-transition: -webkit-transform .3s;
      transition: transform .3s;
      background: rgba(255, 255, 255, 0.4);
      content: "";
      position: absolute;
    }
    &:before {
      top: -webkit-calc(110% - .25em);
      top: calc(110% - .25em);
      left: -webkit-calc(50% - .3em);
      left: calc(50% - .3em);
      width: .6em;
      height: .6em;
      border-radius: 1.2em;
      -webkit-transform: scale(0);
      transform: scale(0)
    }
    &:after {
      top: 110%;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-transform: scaleX(0);
      transform: scaleX(0)
    }

    &:hover{
      color:#fff;
      &:before {
        webkit-transform: scale(1);
        transform: scale(1)
      }

      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
      }
    }
  }
  + .list-item{
    margin-top:10px;
    @media(min-width:768px){
      margin-top:20px;
    }
  }
  .icon{
    display:flex;
    align-items:center;
    justify-content:center;
    > svg{
      fill:#fff;
      width:24px;
      @media(min-width:1200px){
        width:26px;
      }
      @media(min-width:1600px){
        width:30px;
      }
    }
    + .text{
      margin-left:15px;
    }
  }
`


const ContactInfo = () => {
  return (
    <ListInfo className="list-info">
      <ListTitle className="list-title">Office Address</ListTitle>
      <ListItemInfo className="list-item">
        <span className="icon"><MapIcon /></span>
        <span className="text">EMB Metal Buildings, LLC, <br />340 Centennial Drive Heyburn, Idaho 83336</span>
      </ListItemInfo>
    </ListInfo>
  )
}

export default ContactInfo